import React, { ReactNode, useState } from 'react'

import styles from './ListCard.module.scss'

type MissionCardProps = {
  children: ReactNode
  grey?: boolean
  handler: ((...args : unknown[]) => unknown)
}
 
export const ListCard = ({
  children,
  grey,
  handler
}: MissionCardProps) => {
  const [hover, setHover] = useState(false)
  const [focus, setFocus] = useState(false)

  return (
    <div
      className={`${styles.listCard} ${grey && styles.grey} ${hover && styles.hover} ${focus && styles.focus}`}
      onClick={handler}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => { setHover(false); setFocus(false) }}
      onMouseDown={() => setFocus(true)}
      onMouseUp={() => setFocus(false)}
    >
      {children}
    </div>
  )
}
