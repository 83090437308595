import React, { ReactNode, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Outlet } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { Footer } from '../../components/shared/Footer'
import { Header } from '../../components/shared/Header'
import { getPaths } from '../../routing/routes'

import styles from './PageTemplate.module.scss'

import 'react-toastify/dist/ReactToastify.css'


type PageTemplateProps = {
  logedIn?: boolean
  children?: ReactNode
}


export const PageTemplate = ({
  logedIn,
  children
}: PageTemplateProps) => {
  const navigate = useNavigate()
  const [cookies] = useCookies(['email', 'authenticationToken'])
  const [isVerified, setIsVerified] = useState(false)


  useEffect(() => {
    const email = cookies.email || localStorage.getItem('email')
    const authenticationToken = cookies.authenticationToken  || localStorage.getItem('authenticationToken')

    if (logedIn) {
      if (!email || !authenticationToken) {
        navigate(getPaths.managers.signIn)
      }
    } else {
      if (email && authenticationToken) {
        navigate(getPaths.brands.missions.ongoing)
      }
    }

    setIsVerified(true)
  }, [logedIn, navigate, cookies.email, cookies.authenticationToken])


  return (
    <div className={styles.page}>
      <Header logedIn={(logedIn && isVerified)} />
      <ToastContainer />
      <div className={styles.content}>
        {isVerified && (
          <>
            <Outlet />
            {children}
          </>
        )}
      </div>
      <Footer />
    </div>
  )
}
