import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React from 'react'
import { CookiesProvider } from 'react-cookie'
import ReactDOM from 'react-dom/client'

import AppRouter from './routing/index'

import './styles/global.scss'

import './localization'


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <AppRouter />
    </CookiesProvider>
  </LocalizationProvider>
)
