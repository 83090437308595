import React, { MouseEvent, useCallback } from 'react'

import CheckMark from '../../assets/icons/checkMark.svg'
import Spinner from '../../assets/icons/spinner'

import styles from './Button.module.scss'


type ButtonProps = {
  variant?: 'primary'
          | 'primaryWhite'
          | 'secondary'
          | 'danger'
          | 'grey'
          | 'dropdown'
          | 'noContainer'
          | 'link'
  title: string
  titleSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  icon?: string
  disabled?: boolean
  fullWidth?: boolean
  fullHeight?: boolean
  loading?: boolean
  circle?: boolean
  textLeft?: boolean
  noBorderRadiusTop?: boolean
  checkbox?: boolean
  checked?: boolean
  handler: (...args : unknown[]) => unknown
  buttonType?: 'button' | 'submit' | 'reset'
}


export const Button = ({
  variant = 'primary',
  title,
  titleSize,
  icon,
  disabled,
  fullWidth,
  fullHeight,
  loading,
  circle,
  textLeft,
  noBorderRadiusTop,
  checkbox,
  checked,
  handler,
  buttonType = 'button'
}: ButtonProps) => {
  const buttonStyles: { [key: string]: string } = {
    primary: styles.primary,
    primaryWhite: styles.primaryWhite,
    secondary: styles.secondary,
    danger: styles.danger,
    grey: styles.grey,
    dropdown: styles.dropdown,
    noContainer: styles.noContainer,
    link: styles.link
  }

  const titleStyles: { [key: string]: string } = {
    h1: styles.h1,
    h2: styles.h2,
    h3: styles.h3,
    h4: styles.h4,
    h5: styles.h5,
    h6: styles.h6,
  }


  const handlerAux = useCallback((e: MouseEvent) => {
    handler()
    e.stopPropagation()
  }, [handler])


  return (
    <button
      onClick={handlerAux}
      disabled={disabled}
      type={buttonType}
      className={`
        ${styles.button}
        ${buttonStyles[variant]}
        ${fullWidth && styles.fullWidth}
        ${fullHeight && styles.fullHeight}
        ${disabled && styles.disabled}
        ${loading && styles.loading}
        ${circle && styles.circle}
        ${textLeft && styles.textLeft}
        ${noBorderRadiusTop && styles.noBorderRadiusTop}
      `}
    >
      {loading ? (
        <Spinner className={styles.spinner}/>
      ) : (
        <div className={styles.children}>
          {checkbox && (
            <div className={`${styles.checkbox} ${checked && styles.checked} ${disabled && styles.disabled}`}>
              <img
                src={CheckMark}
                alt='V'
                className={`${styles.checkMark} ${checked && styles.checked}`}
                draggable={false}
              />
            </div>
          )}
          {icon && (
            <img
              src={icon}
              alt='buttonIcon'
              className={styles.icon}
              draggable={false}
            />
          )}
          <p className={`${styles.title} ${titleSize && titleStyles[titleSize]}`}>
            {title}
          </p>
        </div>
      )}
    </button>
  )
}
