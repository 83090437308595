import React, { MouseEvent, useCallback } from 'react'

import Spinner from '../../assets/icons/spinner'

import styles from './IconButton.module.scss'


type IconButtonProps = {
  variant?: 'primary' | 'primaryWhite' | 'secondary' | 'danger' | 'grey'
  icon: string
  big?: boolean
  disabled?: boolean
  loading?: boolean
  handler: (...args : unknown[]) => unknown
}

export const IconButton = ({
  variant = 'primary',
  icon,
  big,
  disabled,
  loading,
  handler
}: IconButtonProps) => {
  const buttonStyles: { [key: string]: string } = {
    primary: styles.primary,
    primaryWhite: styles.primaryWhite,
    secondary: styles.secondary,
    danger: styles.danger,
    grey: styles.grey,
    dropdown: styles.dropdown
  }


  const handlerAux = useCallback((e: MouseEvent) => {
    handler()
    e.stopPropagation()
  }, [handler])


  return (
    <button
      onClick={handlerAux}
      disabled={disabled}
      className={`
        ${styles.iconButton}
        ${buttonStyles[variant]}
        ${disabled && styles.disabled}
        ${loading && styles.loading}
        ${big && styles.big}
      `}
    >
      {loading ? (
        <Spinner className={styles.spinner}/>
      ) : (
        <img
          src={icon}
          alt='buttonIcon'
          className={styles.icon}
          draggable={false}
        />
      )}
    </button>
  )
}
