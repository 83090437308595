/* eslint-disable i18next/no-literal-string */
type ID = string | number


export const getPaths = {
  home: '/',
  managers: {
    _base: 'managers',
    signIn: '/managers/sign_in'
  },
  brands: {
    _base: 'brands',
    missions: {
      _base: 'missions',
      ongoing: '/brands/missions/ongoing',
      before_start: '/brands/missions/before_start',
      completed: '/brands/missions/completed',
      in_the_making: '/brands/missions/in_the_making'
    },
    mission: {
      _base: 'mission',
      index: '/brands/mission',
      details: (id: ID) => `/brands/mission/${id}/details`,
      stores_summary: (id: ID) => `/brands/mission/${id}/stores_summary`,
      stats: (id: ID) => `/brands/mission/${id}/stats`,
      answers: {
        _base: 'answers',
        all: (id: ID) => `/brands/mission/${id}/answers/all`,
        to_validate: (id: ID) => `/brands/mission/${id}/answers/to_validate`,
        accepted: (id: ID) => `/brands/mission/${id}/answers/accepted`,
        denied: (id: ID) => `/brands/mission/${id}/answers/denied`
      }
    },
    wizard: {
      _base: 'wizard',
      step_1: (id: ID) => `/brands/wizard/${id}/step_1`,
      step_2: (id: ID) => `/brands/wizard/${id}/step_2`,
      step_3: (id: ID) => `/brands/wizard/${id}/step_3`,
      step_4: (id: ID) => `/brands/wizard/${id}/step_4`
    }
  }
} as const;
