import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { QuestionTypeAPI } from '../../api/types'
import { PercentageBar } from '../../elements/PercentageBar'
import { ListCard } from '../ListCard'
import { QuestionAndValidations } from '../QuestionAndValidations'

import styles from './QuestionStatisticCard.module.scss'


type QuestionStatisticCardProps = {
  question: QuestionTypeAPI
  grey?: boolean
}


export const QuestionStatisticCard = (({
  question,
  grey
}: QuestionStatisticCardProps) => {
  const i18n = useTranslation()

  const [isOpen, setIsOpen] = useState(false)


  const calcTotalAnswers = useCallback((question: QuestionTypeAPI) => {
    return Object.values(question.statistic || {}).reduce((acc, val) => acc + val, 0)
  }, [])


  return (
    <ListCard grey={grey} handler={() => { setIsOpen(!isOpen) }}>
      <div className={styles.questionStatisticCard}>
        <h3>
          {`${question.order+1}) ${question.text}`}
        </h3>
        <div className={styles.expectedAnswer}>
          <h4>
            {`${i18n.t('Mission:ExpectedAnswer')}:`}
          </h4>
          <h5 className={question.expectedAnswer ? undefined : styles.italic}>
            {question.expectedAnswer ? question.expectedAnswer : i18n.t('MissionWizard:NoExpectedAnswer')}
          </h5>
        </div>
        {isOpen && (
          <QuestionAndValidations
            question={question}
            // README: In the future, when a question can have many validations, pass the array of validations to this prop
            validations={[{
              text: question.validationText || '',
              value: question.validationValue || 0,
              maxValidations: question.maxValidations || 0
            }]}
          />
        )}
        <div className={styles.answers}>
          {Object.entries(question.statistic || {}).map(([key, value]) => (
            <div
              key={`statAnswer_${key}`}
              className={styles.answer}
            >
              <span>
                {key}
              </span>
              <PercentageBar percentage={ (Number(value) / calcTotalAnswers(question)) * 100 } />
            </div>
          ))}
        </div>
      </div>
    </ListCard>
  )
})
