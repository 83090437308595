import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { exportMissionData } from '../../api'
import { QuestionTypeAPI } from '../../api/types'
import csvFile from '../../assets/icons/csvFile.svg'
import pdfFile from '../../assets/icons/pdfFile.svg'
import pngFile from '../../assets/icons/pngFile.svg'
import { IconButton } from '../../elements/IconButton'
import { QuestionStatisticCard } from '../QuestionStatisticCard'

import styles from './MissionStatistics.module.scss'


type MissionStatisticsProps = {
  questions: QuestionTypeAPI[]
  missionId: number
}


export const MissionStatistics = (({
  questions,
  missionId
}: MissionStatisticsProps) => {
  const i18n = useTranslation()


  const handleExport = useCallback((type: 'csv' | 'pdf' | 'img') => {
    exportMissionData(missionId, type).then(response => {
      if (!response.success) {
        toast.error(i18n.t('ToastError:ExportFailed'))
        return
      }

      toast.success(i18n.t('ToastSuccess:ExportSuccess'))
    })
}, [missionId, i18n])


  return (
    <div className={styles.missionStatistics}>
      <div className={styles.downloads}>
        <IconButton
          icon={pngFile}
          handler={() => handleExport('img')}
          variant='primaryWhite'
        />
        <IconButton
          icon={pdfFile}
          handler={() => handleExport('pdf')}
          variant='primaryWhite'
        />
        <IconButton
          icon={csvFile}
          handler={() => handleExport('csv')}
          variant='primaryWhite'
        />
      </div>
      {questions.map(question => (
        <QuestionStatisticCard
          key={`questionCard_${question.id}`}
          question={question}
          grey={question.order % 2 === 0}
        />
      ))}
    </div>
  )
})
