import React from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router-dom'

import { RouteNotFound } from '../../../components/RouteNotFound'
import { PageTemplate } from '../../../pages/PageTemplate'
import { getPaths } from '../../../routing/routes'
import { isPositiveInteger } from '../../../utils/regexs'


type RouteParams = {
  id: string
}

const isRouteParams = (params: Record<string, string | undefined>): params is RouteParams => {
  return params.id !== undefined;
}

type RedirectWithIdProps = {
  pathBeforeId: string,
  pathAfterId: string
}


export const RedirectWithId = ({
  pathBeforeId,
  pathAfterId,
}: RedirectWithIdProps) => {
  const i18n = useTranslation()
  const params = useParams()

  if (!isRouteParams(params)) {
    return <Navigate to={pathBeforeId} replace />
  }

  const { id } = params

  // If id is not a positive integer, the route shoundn't match in the :id part of the route
  // which means that the route is not found instead
  if (!isPositiveInteger(id)) {
    return (
      <PageTemplate logedIn>
        <RouteNotFound
          goToText={i18n.t('GoTo:MissionsPage')}
          route={getPaths.brands.missions.ongoing}
        />
      </PageTemplate>
    )
  }

  return <Navigate to={`${pathBeforeId}/${id}${pathAfterId}`} replace />
}
