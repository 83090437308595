import React, { useCallback, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { login } from '../../api'
import { Button } from '../../elements/Button'
import { Checkbox } from '../../elements/Checkbox'
import { TextInput } from '../../elements/TextInput'
import { getPaths } from '../../routing/routes'

import styles from './LoginPage.module.scss'


export const LoginPage = () => {
  const i18n = useTranslation()
  const navigate = useNavigate()
  const [_, setCookie] = useCookies(['email', 'authenticationToken'])

  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [rememberMe, setRememberMe] = useState((localStorage.getItem('email') && localStorage.getItem('authenticationToken')) ? true : false)


  const handleLogin = useCallback(async () => {
    setLoading(true)

    if (email === '') {
      toast.error(i18n.t('ToastError:FillEmailField'))
      setLoading(false)
      return
    }

    if (password === '') {
      toast.error(i18n.t('ToastError:FillPasswordField'))
      setLoading(false)
      return
    }

    login({manager: { email: email, password: password }}).then(response => {
      if (!response.success) {
        toast.error(i18n.t('ToastError:LoginFailed'))
        setLoading(false)
        return
      }

      setCookie('email', response.data.email)
      setCookie('authenticationToken', response.data.authenticationToken)

      if (rememberMe) {
        localStorage.setItem('email', response.data.email)
        localStorage.setItem('authenticationToken', response.data.authenticationToken)
      } else {
        localStorage.removeItem('email')
        localStorage.removeItem('authenticationToken')
      }

      navigate(getPaths.brands.missions.ongoing)
    })
  }, [email, password, i18n, navigate, rememberMe, setCookie])


  return (
    <div className={styles.loginPage}>
      <div className={styles.signInContainer}>
        <h1>
          {i18n.t('Login:SignIn')}
        </h1>
        <TextInput
          title={i18n.t('Login:Email')}
          placeholder={i18n.t('Login:WriteYourEmailHere')}
          value={email}
          onChange={v => setEmail(v)}
          onEnter={handleLogin}
          // eslint-disable-next-line i18next/no-literal-string
          type='email'
        />
        <TextInput
          title={i18n.t('Login:Password')}
          placeholder={i18n.t('Login:WriteYourPasswordHere')}
          value={password}
          onChange={v => setPassword(v)}
          onEnter={handleLogin}
          // eslint-disable-next-line i18next/no-literal-string
          type={showPassword ? 'text' : 'password'}
        />
        <Checkbox
          text={i18n.t('Login:ShowPassword')}
          checked={showPassword}
          handler={() => {setShowPassword(!showPassword)}}
        />
        <Checkbox
          text={i18n.t('Login:RememberMe')}
          checked={rememberMe}
          handler={() => {setRememberMe(!rememberMe)}}
        />
        <Button
          title={i18n.t('Login:Login')}
          handler={handleLogin}
          fullWidth
          loading={loading}
        />
      </div>
    </div>
  )
}
