// @ts-expect-error -> our lib doesn't have types
import denormalize from '@weareredlight/denormalize_json_api'
import { Cookies } from 'react-cookie'

import AppConsts from '../Constants'
import i18n from '../localization'


import { camelizeReviver, decamelizeDeep, serialize } from './networkUtils'
import {
  LoginResponseTypeAPI,
  LoginTypeAPI,
  MissionsTypeAPI,
  MissionTypeAPI,
  MissionUpdateTypeAPI,
  PlaceOrStoreTypeAPI,
  StoresAnswerstypeAPI,
  StoreCitiesTypeAPI,
  StoreFiltersTypeAPI
} from './types'


// README: when everything is in react, this will change (at least the const name to BASE_URL_API or something)
const BASE_URL = `${AppConsts.BASE_URL_OLD_APP}/brands/api/v1`
const DEBUG_ON = false


// ##################################
// ##
// ##     Auth
// ##

export const login = async (data: LoginTypeAPI) => request<LoginResponseTypeAPI>('POST', '/managers/sign_in', data, true, true)

export const logout = async () => request<boolean>('DELETE', '/managers/sign_out')


// ##################################
// ##
// ##     Missions
// ##

export const getMissions = async (
  state: 'ongoing' | 'before_start' | 'completed' | 'in_the_making',
  page: number
) => request<MissionsTypeAPI>('GET', `/missions?filter=${state}&page=${page}`)

export const exportMissionData = async (
  missionId: number,
  type: 'csv' | 'pdf' | 'img'
) => request('POST', `/missions/${missionId}/export?type=${type}`)


// ##################################
// ##
// ##     Answers
// ##

export type AnswersStatusType = 'all' | 'to_validate' | 'accepted' | 'denied'

export const getAnswers = async (
  missionId: number,
  status: AnswersStatusType,
  page: number
) => request<StoresAnswerstypeAPI>('GET', `/missions/${missionId}/answers?filter=${status}&page=${page}`)

export const acceptAnswer = async (missionStoreId: number) => request<StoresAnswerstypeAPI>('PUT', `/answers/${missionStoreId}/accept`)

export const denyAnswer = async (missionStoreId: number) => request<StoresAnswerstypeAPI>('PUT', `/answers/${missionStoreId}/deny`)


// ##################################
// ##
// ##     Wizard
// ##

export type GetPlacesOrStoresFiltersType = {
  page: number
  placeTypeIds?: number[]
  storeChainIds?: number[]
  storeGroupIds?: number[]
  cities?: string[]
  name?: string
}

export const createMission = async () => request<MissionTypeAPI>('POST', '/wizard')

export const createMissionCopy = async (missionId: MissionTypeAPI['id']) => request<MissionTypeAPI>('POST', `/wizard/${missionId}`)

export const updateMission = async (
  id: number,
  data: MissionUpdateTypeAPI
) => request<MissionTypeAPI>('PUT', `/wizard/${id}`, data, true)

export const getMission = async (missionId: MissionTypeAPI['id']) => request<MissionTypeAPI>('GET', `/wizard/${missionId}`)

export const getPlacesOrStores = async (data: GetPlacesOrStoresFiltersType) => {
  /* eslint-disable i18next/no-literal-string */
  let filters = `?page=${data.page}`
  if (data.placeTypeIds) filters += `&place_type_id=${data.placeTypeIds.join(',')}`
  if (data.storeChainIds) filters += `&store_chain_id=${data.storeChainIds.join(',')}`
  if (data.storeGroupIds) filters += `&groups_ids=${data.storeGroupIds.join(',')}`
  if (data.cities) filters += `&city=${data.cities.join(',')}`
  if (data.name) filters += `&name=${data.name}`
  /* eslint-enable i18next/no-literal-string */
  return request<PlaceOrStoreTypeAPI>('GET', `/wizard/stores${filters}`)
}

export const getStoreFilters = async () => request<StoreFiltersTypeAPI>('GET', '/wizard/filters')

export const getStoreCities = async (name: string) => request<StoreCitiesTypeAPI>('GET', `/wizard/cities?name=${name}`)


// ##################################
// ##
// ##     Main function
// ##

type MethodType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'HEAD'

export type ServerResponseType<T> = {
  success: false
  data?: undefined
  error: string
  response: Response
} | {
  success: true
  data: T
  error?: undefined
  response: Response
}

const request = async <T>(method: MethodType, endpoint: string, body?: unknown, multipart?: boolean, isLogin?: boolean): Promise<ServerResponseType<T>> => {
  const cookies = new Cookies()
  let email = cookies.get('email')
  let authenticationToken = cookies.get('authenticationToken')

  if (!email || !authenticationToken) {
    email = localStorage.getItem('email')
    authenticationToken = localStorage.getItem('authenticationToken')
  }

  const headers: HeadersInit = [
    ['X-MANAGER-EMAIL', email],
    ['X-MANAGER-TOKEN', authenticationToken],
  ]

  const options = { method, headers }
  if (body) {
    // @ts-expect-error -> body can be undefined
    options.body = multipart
      ? serialize(decamelizeDeep(body))
      : JSON.stringify(decamelizeDeep(body))
  }

  let response = {} as Response
  try {
    response = await fetch(`${BASE_URL}${endpoint}`, options)
  } catch (error: unknown) {
    DEBUG_ON && console.warn(error)
    return {
      success: false,
      // @ts-expect-error -> error can be undefined
      error: error.message ?? i18n.t('Network:Error'),
      response,
    }
  }

  if (response.status === 401) {
    cookies.remove('email')
    cookies.remove('authenticationToken')
    localStorage.removeItem('authenticationToken')
    if (!isLogin)
      window.location.replace('/managers/sign_in')
  }

  let jsonResponse = null
  try {
    const textContent = await response.text()
    jsonResponse = JSON.parse(textContent, camelizeReviver)
  } catch {
    jsonResponse = {}
  }

  DEBUG_ON && console.debug('#################################################')
  DEBUG_ON && console.debug('### REQUEST', method, endpoint, email || '[no user]', authenticationToken || '[no token]')
  DEBUG_ON && body && console.debug('### REQUEST body', body)
  DEBUG_ON && console.debug('### REQUEST', response.ok ? 'OK' : 'FAILED', response.status)

  let data = null
  if (jsonResponse?.data && jsonResponse?.metadata) {
    data = {
      data: denormalize(jsonResponse.data),
      metadata: jsonResponse.metadata
    }
  } else {
    data = denormalize(jsonResponse)
  }

  if (response.ok) {
    DEBUG_ON && data && console.debug('### DATA', JSON.stringify(data).slice(0, 500))
    DEBUG_ON && console.debug('#################################################')
    return { success: true, data, response }
  }
  DEBUG_ON && console.debug('### ERROR', data?.errors)
  DEBUG_ON && console.debug('#################################################')

  const dataError = data
    ? Array.isArray(data?.errors)
      ? `${data.errors[0].detail || data.errors[0].title || data.errors[0]}`
      : JSON.stringify(data.errors)
    : ''

  return {
    response,
    success: false,
    error: dataError ?? i18n.t('Network:UnknowError', { code: response.status })
  }
}
