import dayjs from 'dayjs'

import i18n from '../localization'


export function humanTimeDiference(
  date1: string | undefined,
  date2: string | undefined
): string {
  if (!date1 || !date2) return ''

  const diff = dayjs(date2).diff(dayjs(date1), 'day', true)
  const days = Math.floor(diff);
  const hours = Math.floor((diff - days) * 24);
  if (days === 0) return `${hours} ${i18n.t('Common:Hours')}`
  if (hours === 0) return `${days} ${i18n.t('Common:Days')}`
  return `${days} ${i18n.t('Common:Days')} ${i18n.t('Common:And')} ${hours} ${i18n.t('Common:Hours')}`
}
