import dayjs from 'dayjs'
import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { toast } from 'react-toastify'

import { AnswersStatusType, createMissionCopy, getMission } from '../../api'
import { MissionTypeAPI } from '../../api/types'
import ArrowLeft from '../../assets/icons/arrowLeft.svg'
import { MissionAnswers } from '../../components/MissionAnswers'
import { MissionDetails } from '../../components/MissionDetails'
import { MissionStatistics } from '../../components/MissionStatistics'
import { CheckIdParamContextType } from '../../components/shared/CheckIdParam/CheckIdParam'
import { Loading } from '../../components/shared/Loading'
import { StoresSummary } from '../../components/StoresSummary'
import { Button } from '../../elements/Button'
import { TabIndicator } from '../../elements/TabIndicator'
import { getPaths } from '../../routing/routes'

import styles from './MissionPage.module.scss'


type MissionPageProps = {
  info: 'details' | 'stores_summary' | 'stats' | 'answers'
  answersStatus?: AnswersStatusType
}


export const MissionPage = ({
  info,
  answersStatus
}: MissionPageProps) => {
  const i18n = useTranslation()
  const navigate = useNavigate()
  const { id: missionId } = useOutletContext<CheckIdParamContextType>()

  const [mission, setMission] = useState<MissionTypeAPI>()

  const tabsRef = useRef<HTMLDivElement>(null)
  const answersTabRef = useRef<HTMLDivElement>(null)
  const [marginLeftAnswersButtons, setMarginLeftAnswersButtons] = useState(0)


  useEffect(() => {
    if (!tabsRef.current || !answersTabRef.current) return

    setMarginLeftAnswersButtons(answersTabRef.current.getBoundingClientRect().left - tabsRef.current.getBoundingClientRect().left)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsRef.current, answersTabRef.current])


  const goBack = useCallback(() => {
    navigate(getPaths.brands.missions.ongoing)
  }, [navigate])


  useEffect(() => {
    getMission(missionId).then(response => {
      if (!response.success) {
        toast.error(i18n.t('ToastError:MissionNotFound'), {autoClose: 2600, pauseOnHover: false})
        setTimeout(() => {
          goBack()
        }, 3000);
        return
      }

      const mission = response.data

      if (mission.status !== 'created') {
        // handle case if user writes a "custom" url and the mission is still in the creation process
        navigate(getPaths.brands.wizard[mission.status](mission.id))
      }

      setMission(mission)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const handleCreateMissionCopy = useCallback(() => {
    if (!mission) return
    createMissionCopy(mission.id).then(response => {
      if (!response.success) {
        toast.error(i18n.t('ToastError:MissionCopyNotCreated'))
        return
      }

      navigate(getPaths.brands.wizard.step_1(response.data.id))
    })
  }, [i18n, navigate, mission])


  return (
    <div>
      <Button
        title={i18n.t('Common:Missions')}
        titleSize='h1'
        icon={ArrowLeft}
        variant='noContainer'
        handler={goBack}
      />
      <div className={styles.top}>
        <h1>
          {mission?.title}
        </h1>
        <Button
          title={i18n.t('Mission:CreateMissionFromThisOne')}
          handler={handleCreateMissionCopy}
        />
      </div>
      <div ref={tabsRef}>
        {mission && (
          <TabIndicator
            currentTab={info}
            currentRef={answersTabRef}
            tabsLeft={[
              {
                id: 'details',
                title: i18n.t('Common:Details'),
                onClick: () => navigate(getPaths.brands.mission.details(mission.id))
              },
              {
                id: 'stores_summary',
                title: i18n.t('Mission:StoresSummary'),
                onClick: () => navigate(getPaths.brands.mission.stores_summary(mission.id))
              },
              {
                id: 'stats',
                title: i18n.t('Common:Statistics'),
                onClick: () => navigate(getPaths.brands.mission.stats(mission.id))
              },
              {
                id: 'answers',
                title: i18n.t('Mission:Answers'),
                onClick: () => navigate(getPaths.brands.mission.answers.all(mission.id))
              }
            ]}
          />
        )}
      </div>
      {!mission ? (
        <Loading />
      ) : (
        info === 'details' ? (
          <MissionDetails
            mission={mission}
            storesToGet='stores'
          />
        ) : info === 'stores_summary' ? (
          <StoresSummary
            stores={mission.stores || []}
            missionFinished={dayjs(mission.endDate).isBefore(dayjs())}
          />
        ) : info === 'stats' ? (
          <MissionStatistics
            questions={mission.questions || []}
            missionId={mission.id}
          />
        ) : (
          <MissionAnswers
            status={answersStatus || 'all'}
            missionId={mission.id}
            questions={mission.questions || []}
            marginLeftButtons={marginLeftAnswersButtons}
          />
        )
      )}
    </div>
  )
}
