import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'


import { QuestionTypeAPI } from '../../api/types'
import Cross from '../../assets/icons/cross.svg'
import { BinaryAnswerType, QuestionsTypeType, WizardConsts } from '../../Constants'
import { Button } from '../../elements/Button'
import { Checkbox } from '../../elements/Checkbox'
import { DecimalNumberInput } from '../../elements/DecimalNumberInput'
import { Dropdown } from '../../elements/Dropdown'
import { IconButton } from '../../elements/IconButton'
import { ImageUploader } from '../../elements/ImageUploader'
import { TextInput } from '../../elements/TextInput'
import { UpdateQuestionParamsType } from '../../pages/Wizard/Step1/Step1'

import styles from './NewQuestion.module.scss'


const { BINARY_ANSWERS, QUESTION_TYPES } = WizardConsts


export type NewQuestionComponentProps = {
  id: number
  question: QuestionTypeAPI
  handleOnDelete: (id: number) => void
  handleUpdate: ({ id, value, toUpdate }: UpdateQuestionParamsType) => void
  handleUpdateAnswerOption: (id: number, value: string,  answerOptionId: number, action: 'add' | 'delete' | 'update') => void
  onlyQuestion: boolean
}


export const NewQuestion = ({
  id,
  question,
  handleOnDelete,
  handleUpdate,
  handleUpdateAnswerOption,
  onlyQuestion
}: NewQuestionComponentProps) => {
  const i18n = useTranslation()

  const [hasExpectedAnswer, setHasExpectedAnswer] = useState<boolean>(question.expectedAnswer ? true : false)


  const updateHasExpectedAnswer = (v: boolean) => {
    if (v) {
      handleUpdate({
        id,
        value: QUESTION_TYPES[question.questionType].defaultExpectedAnswer,
        toUpdate: 'expectedAnswer'
      })
    } else {
      handleUpdate({
        id,
        value: null,
        toUpdate: 'expectedAnswer'
      })
    }
    setHasExpectedAnswer(v)
  }


  const updateQuestionType = (value: keyof typeof QUESTION_TYPES) => {
    setHasExpectedAnswer(false)
    handleUpdate({
      id,
      value,
      toUpdate: 'questionType'
    })
  }


  return (
    <div className={styles.question}>
      <div className={styles.id}>
        <span className={styles.idText}>
          {`${i18n.t('Common:Question')} ${id+1}`}
        </span>
      </div>
      <div className={styles.photo}>
        <ImageUploader
          image={question.image}
          handler={value => handleUpdate({
            id,
            value,
            toUpdate: 'image'
          })}
        />
      </div>
      <div className={styles.rightSide}>
        <div className={styles.top}>
          <div className={styles.row}>
            <div className={styles.semiRow}>
              <TextInput
                title={i18n.t('Mission:ShortName')}
                placeholder={i18n.t('MissionWizard:WriteShortNameHere')}
                value={question.shortName || ''}
                onChange={value => handleUpdate({
                  id,
                  value,
                  toUpdate: 'shortName'
                })}
              />
            </div>
            <div className={`${styles.semiRow} ${styles.gap}`}>
              <Checkbox
                checked={question.requiresPhotoValidation}
                text={i18n.t('MissionWizard:NeedsValidationPhoto')}
                fullHeight
                handler={() => handleUpdate({
                  id,
                  value: !question.requiresPhotoValidation,
                  toUpdate: 'requiresPhotoValidation'
                })}
              />
              <Checkbox
                checked={question.toValidate}
                text={i18n.t('MissionWizard:ToValidate')}
                disabled={!question.requiresPhotoValidation || !question.image || !QUESTION_TYPES[question.questionType].validationEnabled}
                fullHeight
                handler={() => handleUpdate({
                  id,
                  value: !question.toValidate,
                  toUpdate: 'toValidate'
                })}
              />
              <IconButton
                icon={Cross}
                handler={() => handleOnDelete(id)}
                disabled={onlyQuestion}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.semiRow}>
              <Dropdown<QuestionsTypeType>
                options={Object.values(QUESTION_TYPES)}
                selected={QUESTION_TYPES[question.questionType]}
                getOptionLabel={opt => opt.label}
                getOptionId={opt => opt.id}
                title={i18n.t('Mission:QuestionType')}
                onChange={opt => updateQuestionType(opt.id)}
              />
            </div>
            <div className={styles.semiRow}>
              <Checkbox
                handler={() => updateHasExpectedAnswer(!hasExpectedAnswer)}
                checked={question.expectedAnswer !== null}
                disabled={!QUESTION_TYPES[question.questionType].expectedAnswerEnabled}
                fullHeight
              />
              {question.questionType === 'binary' ? (
                <Dropdown<BinaryAnswerType>
                  options={Object.values(BINARY_ANSWERS)}
                  selected={question.expectedAnswer ? BINARY_ANSWERS[question.expectedAnswer] : null}
                  getOptionLabel={opt => opt.label}
                  getOptionId={opt => opt.id}
                  title={i18n.t('Mission:ExpectedAnswer')}
                  disabled={question.expectedAnswer === null}
                  onChange={opt => handleUpdate({
                    id,
                    value: opt.id,
                    toUpdate: 'expectedAnswer'
                  })}
                />
              ) : (
                <DecimalNumberInput
                  title={i18n.t('Mission:ExpectedAnswer')}
                  value={question.expectedAnswer || 0}
                  disabled={question.expectedAnswer === null}
                  onChange={v => handleUpdate({
                    id,
                    value: v.toString(),
                    toUpdate: 'expectedAnswer'
                  })}
                />
              )}
            </div>
          </div>
          <TextInput
            title={i18n.t('Common:Question')}
            placeholder={i18n.t('MissionWizard:WriteQuestionHere')}
            value={question.text}
            onChange={v => handleUpdate({
              id,
              value: v,
              toUpdate: 'text'
            })}
            multiline
          />
        </div>
        {QUESTION_TYPES[question.questionType].hasOptions && (
          <div className={styles.bottom}>
            <h3>
              {i18n.t('Mission:AnswerOptions')}
            </h3>
            {question.answerOptions.map((ao, idx) => (
              <div
                key={`question_${id}_choice_${idx}`}
                className={styles.choice}
              >
                <div className={styles.choiceId}>
                  <span>
                    {idx+1}
                  </span>
                </div>
                <TextInput
                  placeholder={i18n.t('MissionWizard:WriteOptionHere', {id: idx+1})}
                  value={ao.text}
                  onChange={v => handleUpdateAnswerOption(id, v, idx, 'update')}
                />
                <IconButton
                  icon={Cross}
                  handler={() => handleUpdateAnswerOption(id, '', idx, 'delete')}
                  disabled={question.answerOptions.length === 2}
                />
              </div>
            ))}
            <Button
              title={i18n.t('MissionWizard:AddOption')}
              handler={() => handleUpdateAnswerOption(id, '', 0, 'add')}
              variant='primaryWhite'
              fullWidth
            />
          </div>
        )}
      </div>
    </div>
  )
}
