import React, { InputHTMLAttributes, KeyboardEvent, TextareaHTMLAttributes, useCallback } from 'react'

import styles from './TextInput.module.scss'


type TextInputProps = {
  title?: string
  onChange: (v: string) => void
  multiline?: boolean
  noBorders?: boolean
  disabled?: boolean
  endSymbol?: string
  maxLength?: number
  onEnter?: () => void
} & (Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>)
  & (Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'>)


export const TextInput = ({
  title,
  placeholder,
  value,
  onChange,
  multiline,
  noBorders,
  disabled,
  endSymbol,
  maxLength,
  onEnter,
  ...rest
}: TextInputProps) => {
  const handleOnChange = (v: string) => {
    if (onEnter && v[v.length - 1] === '\n') {
      onEnter()
      return
    }

    let newValue = v.trimStart()

    if (endSymbol) {
      if (newValue.includes(endSymbol)) {
        newValue = newValue.replace(endSymbol, '')
      } else {
        // Remove last character if v doesn't contain the end symbol because the user
        // tried to erase a char and removed the symbol instead, so we remove here
        newValue = newValue.slice(0, -1)
      }
    }

    if (maxLength && newValue.length > maxLength) {
      newValue = newValue.slice(0, maxLength)
    }

    onChange(newValue)
  }


  const handleOnKeyPress = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (onEnter && e.key === 'Enter') {
      onEnter()
    }
  }, [onEnter])


  return (
    <div className={`${styles.textInput} ${noBorders && styles.noBorders} ${disabled && styles.disabled}`}>
      <h4 className={styles.title}>
        {title}
      </h4>
      {multiline ? (
        <textarea
          className={`${styles.input} ${disabled && styles.disabled}`}
          value={disabled ? '' : `${value}${endSymbol ?? ''}`}
          onChange={e => handleOnChange(e.target.value)}
          placeholder={placeholder ?? ''}
          rows={5}
          {...rest}
        />
      ) : (
        <input
          className={`${styles.input} ${disabled && styles.disabled}`}
          value={disabled ? '' : `${value}${endSymbol ?? ''}`}
          onChange={e => handleOnChange(e.target.value)}
          placeholder={placeholder ?? ''}
          onKeyDown={e => handleOnKeyPress(e)}
          {...rest}
        />
      )}
    </div>
  )
}
