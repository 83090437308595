import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { AnswersStatusType, getAnswers } from '../../api'
import { QuestionTypeAPI, StoreAnswersTypeAPI } from '../../api/types'
import { PageIndicator } from '../../elements/PageIndicator'
import { TabIndicator } from '../../elements/TabIndicator'
import { getPaths } from '../../routing/routes'
import { AnswerCard } from '../AnswerCard'
import { Loading } from '../shared/Loading'

import styles from './MissionAnswers.module.scss'


type MissionStatisticsProps = {
  status: AnswersStatusType
  missionId: number
  questions: QuestionTypeAPI[]
  marginLeftButtons: number
}


export const MissionAnswers = ({
  status,
  missionId,
  questions,
  marginLeftButtons
}: MissionStatisticsProps) => {
  const i18n = useTranslation()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [storesAnswers, setStoresAnswers] = useState<StoreAnswersTypeAPI[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)


  useEffect(() => {
    setLoading(true)
    getAnswers(missionId, status, 1).then(response => {
      setStoresAnswers(response.data?.data ?? [])
      setLastPage(response.data?.metadata?.lastPage ?? 1)
      setLoading(false)
    })
  }, [missionId, status])


  const setPage = useCallback((page: number) => {
    setLoading(true)
    getAnswers(missionId, status, page).then(response => {
      setStoresAnswers(response.data?.data ?? [])
      setCurrentPage(page)
      setLastPage(response.data?.metadata?.lastPage ?? 1)
      setLoading(false)
    })
  }, [missionId, status])


  const setStatus = useCallback((status: AnswersStatusType) => {
    navigate(getPaths.brands.mission.answers[status](missionId))
  }, [missionId, navigate])


  return (
    <div>
      <div style={{ marginLeft: marginLeftButtons }}>
        <TabIndicator
          isSecondaryTabIndicator
          currentTab={status}
          tabsLeft={[
            {
              id: 'all',
              title: i18n.t('Answers:All'),
              onClick: () => setStatus('all')
            },
            {
              id: 'to_validate',
              title: i18n.t('Answers:ToValidate'),
              onClick: () => setStatus('to_validate')
            },
            {
              id: 'accepted',
              title: i18n.t('Answers:Accepted'),
              onClick: () => setStatus('accepted')
            },
            {
              id: 'denied',
              title: i18n.t('Answers:Denied'),
              onClick: () => setStatus('denied')
            }
          ]}
        />
      </div>
      {loading ? (
        <Loading />
      ) : (
        storesAnswers.length === 0 ? (
          <div className={styles.noAnswers}>
            <h3>
              {i18n.t('Answers:NoAnswers')}
            </h3>
          </div>
        ) : (
          <>
            <div className={styles.missionsList}>
              {storesAnswers.map((storeAnswers, index) => (
                <div key={`answerCard_${storeAnswers.storeName}_mission_${missionId}`}>
                  <AnswerCard
                    storeAnswers={storeAnswers}
                    questions={questions}
                    grey={index % 2 === 0}
                  />
                </div>
              ))}
            </div>
            <PageIndicator
              currentPage={currentPage}
              lastPage={lastPage}
              handler={page => setPage(page)}
            />
          </>
        )
      )}
    </div>
  )
}
