import React, { RefObject } from 'react'

import styles from './TabIndicator.module.scss'


type TabProps = {
  id: string
  title: string
  onClick: () => void
}

type TabIndicatorProps = {
  isSecondaryTabIndicator?: boolean
  currentTab: string
  currentRef?: RefObject<HTMLDivElement>
  tabsLeft: TabProps[]
  tabsRight?: TabProps[]
}


export const TabIndicator = ({
  isSecondaryTabIndicator,
  currentTab,
  currentRef,
  tabsLeft,
  tabsRight
}: TabIndicatorProps) => (
  <div className={`${styles.tabIndicator} ${isSecondaryTabIndicator && styles.secondaryTabIndicator}`}>
    <div className={styles.tabs}>
      {tabsLeft.map(tab => (
        <div
          key={`tab_${tab.id}`}
          ref={currentTab === tab.id ? currentRef : null}
        >
          <button
            className={`${styles.tab} ${currentTab === tab.id && styles.tabActive}`}
            onClick={tab.onClick}
          >
            <p>
              {tab.title}
            </p>
          </button>
        </div>
      ))}
    </div>
    {tabsRight && (
      <div className={styles.tabs}>
        {tabsRight.map(tab => (
          <div
            key={`tab_${tab.id}`}
            ref={currentTab === tab.id ? currentRef : null}
          >
            <button
              className={`${styles.tab} ${currentTab === tab.id && styles.tabActive}`}
              onClick={tab.onClick}
            >
              <p>
                {tab.title}
              </p>
            </button>
          </div>
        ))}
      </div>
    )}
  </div>
)
