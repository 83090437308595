import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'

import ArrowDown from '../../assets/icons/arrowDown.svg'
import ArrowUp from '../../assets/icons/arrowUp.svg'
import { Button } from '../Button'
import { TextInput } from '../TextInput'

import styles from './Dropdown.module.scss'


export type DropdownProps<T> = {
  options: T[]
  selected: T | null
  onChange: (v: T) => void
  getOptionLabel: (item: T) => string
  getOptionId: (item: T) => string
  filterBy?: (item: T) => string
  title?: string
  disabled?: boolean
}

export const Dropdown = <T, >({
  options,
  selected,
  onChange,
  getOptionLabel,
  getOptionId,
  filterBy,
  title,
  disabled
}: DropdownProps<T>) => {
  const i18n = useTranslation()

  const [isDroped, setIsDroped] = useState(false)
  const [isHoverOptions, setIsHoverOptions] = useState(false)
  const [filter, setFilter] = useState('')

  const containerRef = useRef(null)
  const droppedListRef = useRef(null)


  // Filter the options if filterBy is present
  const filteredOptions = useMemo(() => {
    if (!filterBy) return options

    return options.filter(option => filterBy(option).toLowerCase().includes(filter.toLowerCase()))
  }, [options, filter, filterBy])


  // Handles the click logic
  useEffect(() => {
    const handleClick = () => {
      setIsDroped(false)
    }

    isHoverOptions ? null : window.addEventListener('click', handleClick)

    return () => {
      window.removeEventListener('click', handleClick)
    }
  }, [isHoverOptions])


  // Clear filter when dropdown is closed
  useEffect(() => {
    isDroped ? null : setFilter('')
  }, [isDroped])


  // Handle select option
  const selectOption = useCallback((opt: T) => {
    setIsDroped(false)
    setIsHoverOptions(false)
    onChange(opt)
  }, [onChange])


  return (
    <div className={`${styles.dropdown} ${disabled && styles.disabled}`}>
      <button
        className={`${styles.topContainer} ${disabled && styles.disabled}`}
        onClick={() => setIsDroped(!isDroped)}
        ref={containerRef}
        onMouseEnter={() => setIsHoverOptions(true)}
        onMouseLeave={() => setIsHoverOptions(false)}
      >
        <div className={styles.info}>
          <h4 className={styles.title}>
            {title}
          </h4>
          <span>
            {selected ? getOptionLabel(selected) : ''}
          </span>
        </div>
        <div className={styles.imgContainer}>
          <img
            src={isDroped ? ArrowUp : ArrowDown}
            alt='DropdownArrow'
            draggable={false}
          />
        </div>
      </button>
      {isDroped && (
        <div
          ref={droppedListRef}
          className={styles.dropedList}
          onMouseEnter={() => setIsHoverOptions(true)}
          onMouseLeave={() => setIsHoverOptions(false)}
        >
          {filterBy && (
            <TextInput
              value={filter}
              onChange={v => setFilter(v)}
              placeholder={i18n.t('Common:SearchHere')}
              noBorders
            />
          )}
          {filteredOptions.map(opt => (
            <div
              key={`dropdownOption_${getOptionId(opt)}`}
              className={styles.optionContainer}
            >
              <Button
                title={getOptionLabel(opt)}
                handler={() => selectOption(opt)}
                variant='dropdown'
                fullWidth
              />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
