import React from 'react'

import arrowLeft from '../../assets/icons/arrowLeft.svg'
import arrowRight from '../../assets/icons/arrowRight.svg'
import { Button } from '../Button'
import { IconButton } from '../IconButton'

import styles from './PageIndicator.module.scss'


type PageIndicatorProps = {
  currentPage: number
  lastPage: number
  handler: (page: number) => void
}


export const PageIndicator = ({
  currentPage,
  lastPage,
  handler
}: PageIndicatorProps) => {
  return (
    <div className={styles.pageIndicator}>
      <IconButton
        variant='primaryWhite'
        icon={arrowLeft}
        handler={() => handler(currentPage - 1)}
        disabled={currentPage === 1}
      />
      {currentPage <= 2 ? (
        <div className={styles.bigSpacer} />
      ) : (
        <>
          <Button
            variant='primaryWhite'
            title='1'
            handler={() => handler(1)}
            circle
          />
          {/* eslint-disable-next-line i18next/no-literal-string */}
          <span className={styles.dots}>
            . . .
          </span>
        </>
      )}
      {currentPage === 1 ? (
        <div className={styles.buttonSpacer} />
      ) : (
        <Button
          variant='primaryWhite'
          title={`${currentPage - 1}`}
          handler={() => handler(currentPage - 1)}
          circle
        />
      )}
      <Button
        title={`${currentPage}`}
        handler={() => handler(currentPage)}
        circle
      />
      {currentPage === lastPage ? (
        <div className={styles.buttonSpacer} />
      ) : (
        <Button
          variant='primaryWhite'
          title={`${currentPage + 1}`}
          handler={() => handler(currentPage + 1)}
          circle
        />
      )}
      {currentPage >= lastPage - 1 ? (
        <div className={styles.bigSpacer} />
      ) : (
        <>
          {/* eslint-disable-next-line i18next/no-literal-string */}
          <span className={styles.dots}>
            . . .
          </span>
          <Button
            variant='primaryWhite'
            title={`${lastPage}`}
            handler={() => handler(lastPage)}
            circle
          />
        </>
      )}
      <IconButton
        variant='primaryWhite'
        icon={arrowRight}
        handler={() => handler(currentPage + 1)}
        disabled={currentPage === lastPage}
      />
    </div>
  )
}
