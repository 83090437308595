import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo
} from 'react'
import { useTranslation } from 'react-i18next'

import { ServerResponseType } from '../../api/index'
import ArrowDown from '../../assets/icons/arrowDown.svg'
import ArrowUp from '../../assets/icons/arrowUp.svg'
import { Button } from '../Button'
import { Scroll } from '../Scroll'
import { TextInput } from '../TextInput'

import styles from './MultiDropdownFetch.module.scss'


export type MultiDropdownFetchProps<T> = {
  selected: T[]
  fetcher: (name: string) => Promise<ServerResponseType<{[dataKey: string]: T[]}>>
  dataKey: string
  onChange:  (v: T[]) => void
  getOptionLabel: (item: T) => string
  getOptionId: (item: T) => string
  title?: string
  disabled?: boolean
  height?: string
}

export const MultiDropdownFetch = <T, >({
  selected,
  fetcher,
  dataKey,
  onChange,
  getOptionLabel,
  getOptionId,
  title,
  disabled,
  height
}: MultiDropdownFetchProps<T>) => {
  const i18n = useTranslation()

  const [options, setOptions] = useState<T[]>([])
  const [filter, setFilter] = useState('')
  const [isDroped, setIsDroped] = useState(false)
  const [isHoverTopContainer, setIsHoverTopContainer] = useState(false)
  const [isHoverOptions, setIsHoverOptions] = useState(false)

  const containerRef = useRef(null)
  const droppedListRef = useRef(null)

  const selectedIds = useMemo(() => selected.map(getOptionId), [selected, getOptionId])


  // Returns the label for the dropdown with info of the selected options
  const dropdownLabel = useMemo(() => {
    const countSelected = selected.length
    if (countSelected === 0) return ''
    if (countSelected === 1) return getOptionLabel(selected[0])
    return `${getOptionLabel(selected[0])}, ...(${countSelected})`
  }, [selected, getOptionLabel])


  // Handles the click outside the dropdown logic
  useEffect(() => {
    if (isHoverTopContainer) return () => null

    const handleOutsideClick = () => {
      if (!isHoverOptions) setIsDroped(false)
    }

    window.addEventListener('click', handleOutsideClick)
    return () => window.removeEventListener('click', handleOutsideClick)
  }, [isHoverOptions, isHoverTopContainer])


  // Handle adding or removing an option from the selected list
  const updateSelectedList = useCallback((opt: T) => {
    const optId = getOptionId(opt)
    const newSelected = selectedIds.includes(optId)
      ? selected.filter(s => getOptionId(s) !== optId)
      : [...selected, opt]

    onChange(newSelected)
  }, [selected, selectedIds, onChange, getOptionId])


  // Fetch the options when the filter changes after X time
  useEffect(() => {
    const fetcherTimeout = setTimeout(() => {
      if (filter === '') { 
        setOptions([])
        return
      }
      fetcher(filter).then(response => {
        if (response.data && response.data[dataKey]) setOptions(response.data[dataKey])
        else setOptions([])
      })
    }, 1000)
    return () => clearTimeout(fetcherTimeout)
  }, [fetcher, selected, filter, dataKey])


  useEffect(() => {
    isDroped ? null : setFilter('')
  }, [isDroped])


  return (
    <div className={`${styles.dropdown} ${disabled && styles.disabled}`}>
      <button
        className={`${styles.topContainer} ${disabled && styles.disabled}`}
        onClick={() => setIsDroped(!isDroped)}
        ref={containerRef}
        onMouseEnter={() => setIsHoverTopContainer(true)}
        onMouseLeave={() => setIsHoverTopContainer(false)}
      >
        <div className={styles.info}>
          <h4 className={styles.title}>
            {title}
          </h4>
          <span>
            {dropdownLabel}
          </span>
        </div>
        <div className={styles.imgContainer}>
          <img
            src={isDroped ? ArrowUp : ArrowDown}
            alt='DropdownArrow'
            draggable={false}
          />
        </div>
      </button>
      {isDroped && (
        <div
          ref={droppedListRef}
          className={styles.dropedList}
          onMouseEnter={() => setIsHoverOptions(true)}
          onMouseLeave={() => setIsHoverOptions(false)}
        >
          <TextInput
            value={filter}
            onChange={v => setFilter(v)}
            placeholder={i18n.t('Common:SearchHere')}
            noBorders
          />
          <Scroll<T>
            options={options}
            height={height}
            variant='dropdown'
            getOptionKey={option => getOptionId(option)}
            getOptionComponent={opt => (
              <Button
                title={getOptionLabel(opt)}
                handler={() => updateSelectedList(opt)}
                fullWidth
                textLeft
                checkbox
                checked={selectedIds.includes(getOptionId(opt))}
                variant='dropdown'
              />
            )}
          />
        </div>
      )}
    </div>
  )
}
