import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Routes,
  Route
} from 'react-router-dom'

import { RouteNotFound } from '../components/RouteNotFound'
import { CheckIdParam } from '../components/shared/CheckIdParam'
import { RedirectWithId } from '../components/shared/RedirectWithId'
import i18n from '../localization'
import { LoginPage } from '../pages/Login/LoginPage'
import { MissionPage } from '../pages/Mission'
import { MissionsPage } from '../pages/Missions'
import { PageTemplate }from '../pages/PageTemplate'
import { Step1 } from '../pages/Wizard/Step1'
import { Step2 } from '../pages/Wizard/Step2'
import { Step3 } from '../pages/Wizard/Step3'
import { Step4 } from '../pages/Wizard/Step4'
import { Wizard } from '../pages/Wizard/Wizard'

import { getPaths } from './routes'


const AppRouter = () => {
  return (
    <Router>
      <Routes>

        <Route
          path={getPaths.managers._base}
          element={<PageTemplate />}
        >
          <Route
            path='sign_in'
            element={<LoginPage />}
          />
          <Route
            path=''
            element={<Navigate to={getPaths.managers.signIn} replace />}
          />
          <Route
            path='*'
            element={
              <RouteNotFound
                goToText={i18n.t('GoTo:SignInPage')}
                route={getPaths.managers.signIn}
              />
            }
          />
        </Route>

        <Route
          path={getPaths.brands._base}
          element={<PageTemplate logedIn />}
        >
          <Route
            path={getPaths.brands.missions._base}
            element={<Outlet />}
          >
            <Route
              path='ongoing'
              element={<MissionsPage state={'ongoing'} />}
            />
            <Route
              path='before_start'
              element={<MissionsPage state={'before_start'} />}
            />
            <Route
              path='completed'
              element={<MissionsPage state={'completed'} />}
            />
            <Route
              path='in_the_making'
              element={<MissionsPage state={'in_the_making'} />}
            />
            <Route
              path=''
              element={<Navigate to={getPaths.brands.missions.ongoing} replace />}
            />
          </Route>

          <Route
            path={getPaths.brands.mission._base}
            element={<Outlet />}
          >
            <Route
              path=':id'
              element={
                <CheckIdParam
                  errorText={i18n.t('ToastError:MissionIdNotFound')}
                  goToText={i18n.t('GoTo:MissionsPage')}
                  redirectPath={getPaths.brands.missions.ongoing}
                />
                }
              >
              <Route
                path='details'
                element={<MissionPage info={'details'} />}
              />
              <Route
                path='stores_summary'
                element={<MissionPage info={'stores_summary'} />}
              />
              <Route
                path='stats'
                element={<MissionPage info={'stats'} />}
              />
              <Route
                path={getPaths.brands.mission.answers._base}
                element={
                  <CheckIdParam
                    errorText={i18n.t('ToastError:MissionIdNotFound')}
                    goToText={i18n.t('GoTo:MissionsPage')}
                    redirectPath={getPaths.brands.missions.ongoing}
                  />
                }
              >
                <Route
                  path='all'
                  element={<MissionPage info={'answers'} answersStatus={'all'} />}
                />
                <Route
                  path='to_validate'
                  element={<MissionPage info={'answers'} answersStatus={'to_validate'} />}
                />
                <Route
                  path='accepted'
                  element={<MissionPage info={'answers'} answersStatus={'accepted'} />}
                />
                <Route
                  path='denied'
                  element={<MissionPage info={'answers'} answersStatus={'denied'} />}
                />
                <Route
                  path=''
                  element={
                    <RedirectWithId
                      pathBeforeId={getPaths.brands.mission.index}
                      pathAfterId='/answers/all'
                    />
                  }
                />
              </Route>
              <Route
                path=''
                element={
                  <RedirectWithId
                    pathBeforeId={getPaths.brands.mission.index}
                    pathAfterId='/details'
                  />
                }
              />
            </Route>
          </Route>

          <Route
            path={getPaths.brands.wizard._base}
            element={<Outlet />}
          >
            <Route
              path=':id'
              element={(
                <CheckIdParam
                  errorText={i18n.t('ToastError:MissionIdNotFound')}
                  goToText={i18n.t('GoTo:MissionsPage')}
                  redirectPath={getPaths.brands.missions.ongoing}
                >
                  {id => <Wizard missionId={id} />}
                </CheckIdParam>
              )}>
              <Route
                path='step_1'
                element={<Step1 />}
              />
              <Route
                path='step_2'
                element={<Step2 />}
              />
              <Route
                path='step_3'
                element={<Step3 />}
              />
              <Route
                path='step_4'
                element={<Step4 />}
              />
            </Route>
          </Route>

          <Route
            path=''
            element={<Navigate to={getPaths.brands.missions.ongoing} replace />}
          />
          <Route
            path='*'
            element={
              <RouteNotFound
                goToText={i18n.t('GoTo:MissionsPage')}
                route={getPaths.brands.missions.ongoing}
              />
            }
          />
        </Route>
        <Route
          path=''
          element={<Navigate to={getPaths.managers.signIn} replace />}
        />
        <Route
          path='*'
          element={
            <PageTemplate>
              <RouteNotFound
                goToText={i18n.t('GoTo:SignInPage')}
                route={getPaths.managers.signIn}
              />
            </PageTemplate>
          }
        />
      </Routes>
    </Router>
  )
}

export default AppRouter
