import { DateTimePicker as DateTimePickerMUI } from '@mui/x-date-pickers/DateTimePicker'
import dayjs, { Dayjs } from 'dayjs'
import React from 'react'

import styles from './DateTimePicker.module.scss'


type DateTimePickerProps = {
  label?: string
  onChange: (value: Dayjs | null) => void
  value: Dayjs | null
  disablePast?: boolean
  minDateTime?: Dayjs
}

export const DateTimePicker = ({
  label,
  value,
  onChange,
  disablePast,
  minDateTime
}: DateTimePickerProps) => {
  return (
    <DateTimePickerMUI
      className={styles.dateTimePicker}
      label={label}
      value={dayjs(value)}
      onChange={onChange}
      disablePast={disablePast}
      ampm={false}
      minDateTime={minDateTime}
      format='DD/MM/YYYY HH:mm'
    />
  )
}
