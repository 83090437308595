import React, { useEffect, useState } from 'react'

import { useObserveElementWidth } from '../../hooks/useObserveElementWidth'

import styles from './PercentageBar.module.scss'


type PercentageBarProps = {
  percentage: number
}

export const PercentageBar = ({percentage}: PercentageBarProps) => {
  const [progressBarWidth, setProgressBarWidth] = useState(0)
  const [percentageOutside, setPercentageOutside] = useState(false)
  const percentageBarObserver = useObserveElementWidth<HTMLDivElement>()
  const progressBarObserver = useObserveElementWidth<HTMLDivElement>()


  useEffect(() => {
    setProgressBarWidth(percentageBarObserver.width * percentage / 100)
    if (progressBarObserver.ref.current && percentageBarObserver.ref.current) {
      if (progressBarObserver.width < 30) {
        setPercentageOutside(true)
      } else {
        setPercentageOutside(false)
      }
    }
  }, [percentage, percentageBarObserver.ref, percentageBarObserver.width, progressBarObserver.ref, progressBarObserver.width])


  return (
    <div className={styles.percentageBar} ref={percentageBarObserver.ref}>
      <div className={styles.progressBar} style={{ width: progressBarWidth }} ref={progressBarObserver.ref}>
        <h6 className={`${styles.percentage} ${percentageOutside && styles.outside}`}>{`${percentage}%`}</h6>
      </div>
    </div>
  )
}
