import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { isPositiveInteger } from '../../../utils/regexs'
import { RouteNotFound } from '../../RouteNotFound'
import { Loading } from '../Loading'


type IdParams = {
  id: string
}

const isIdParams = (params: Record<string, string | undefined>): params is IdParams => {
  return params.id !== undefined;
}

type CheckIdParamProps = {
  errorText: string
  goToText: string
  redirectPath: string
  children?: (id: number) => React.ReactNode
}

export type CheckIdParamContextType = {
  id: number
}


export const CheckIdParam = ({
  errorText,
  goToText,
  redirectPath,
  children
}: CheckIdParamProps) => {
  const navigate = useNavigate()
  const params = useParams()

  const [loading, setLoading] = useState(true)
  const [invalidPath, setInvalidPath] = useState(false)


  useEffect(() => {
    if (!isIdParams(params)) {
      toast.error(errorText, {autoClose: 2600, pauseOnHover: false})
      navigate(redirectPath)
      return
    }
    const { id } = params
    if (!isPositiveInteger(id)) setInvalidPath(true)
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        invalidPath ? (
          <RouteNotFound
            goToText={goToText}
            route={redirectPath}
          />
        ) : (
          /* eslint-disable @typescript-eslint/no-non-null-assertion */
          children ? (
            children(parseInt(params.id!, 10))
          ) : (
            <Outlet context={{ id: parseInt(params.id!, 10) } satisfies CheckIdParamContextType}/>
          )
          /* eslint-enable @typescript-eslint/no-non-null-assertion */
        )
      )}
    </>
  )
}
