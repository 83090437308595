import React, { useCallback } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { logout } from '../../../api'
import LogoIcon from '../../../assets/icons/logo.svg'
import { Button } from '../../../elements/Button'
import { getPaths } from '../../../routing/routes'

import styles from './Header.module.scss'


type HeaderProps = {
  logedIn?: boolean
}


export const Header = ({logedIn}: HeaderProps) => {
  const i18n = useTranslation()
  const navigate = useNavigate()
  const [_, __, removeCookie] = useCookies(['email', 'authenticationToken'])


  const handleLogout = useCallback(async () => {
    logout().then(() => {
      removeCookie('email')
      removeCookie('authenticationToken')
      localStorage.removeItem('authenticationToken')
      navigate(getPaths.managers.signIn)
    })
  }, [removeCookie, navigate])


  return (
    <header className={styles.header}>
      <button 
        onClick={() => navigate(getPaths.brands.missions.ongoing)}
        className={styles.logoContainer}
      >
        <img
          src={LogoIcon}
          alt='icon'
          className={styles.logo}
          draggable={false}
        />
      </button>
      {logedIn && (
        <div className={styles.links}>
          <Button
            title={i18n.t('Common:Missions')}
            handler={() => navigate(getPaths.brands.missions.ongoing)}
            variant='link'
          />
          <Button
            title={i18n.t('Common:Stores')}
            handler={() => navigate(getPaths.brands.missions.ongoing)}
            variant='link'
            disabled
          />
          <Button
            title={i18n.t('Header:Profile')}
            handler={() => navigate(getPaths.brands.missions.ongoing)}
            variant='link'
            disabled
          />
          <Button
            title={i18n.t('Header:Credits')}
            handler={() => navigate(getPaths.brands.missions.ongoing)}
            variant='link'
            disabled
          />
          <Button
            title={i18n.t('Header:EndSession')}
            handler={handleLogout}
            variant='link'
          />
        </div>
      )}
    </header>
  )
}
