import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import { Button } from '../Button'

import styles from './ImageUploader.module.scss'


type ImageUploaderProps = {
  image: string | File | null
  handler: (image: File | null) => void
}


export const ImageUploader = ({
  image,
  handler
}: ImageUploaderProps) => {
  const i18n = useTranslation()

  const [imageData, setImageData] = useState<string | ArrayBuffer | null>(null)


  const onDrop = useCallback((acceptedFiles: File[]) => {
    handler(acceptedFiles[0])
  }, [handler])


  useEffect(() => {
    if (image) {
      if (image instanceof File) {
        const reader = new FileReader
        reader.readAsDataURL(image)
        reader.onload = function() {
          setImageData(reader.result)
        }
      } else if (typeof image === 'string') {
        setImageData(image)
      }
    } else {
      setImageData(null)
    }
  }, [image])


  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      /* eslint-disable-next-line i18next/no-literal-string */
      'image/*': ['.png', '.jpeg', '.jpg'],
    }
  })


  return (
    <div className={styles.imageUploader}>
      <div {...getRootProps()} className={styles.imageUploaderBox}>
        <input {...getInputProps()} />
        {!imageData && (
          isDragActive ? (
            <p>
              {i18n.t('ImageUploader:DropHere')}
            </p>
          ) : (
            <p>
              {i18n.t('ImageUploader:ClickDragHere')}
            </p>
          )
        )}
        {image && (
          <div className={styles.imgContainer}>
            <img
              src={imageData ? imageData.toString() : undefined}
              alt='QuestionImage'
              draggable={false}
            />
          </div>
        )}
      </div>
      <div className={styles.buttonContainer}>
        <Button
          handler={() => handler(null)}
          title={i18n.t('Common:Remove')}
          fullWidth
          fullHeight
          disabled={!image}
          noBorderRadiusTop
        />
      </div>
    </div>
  )
}
