import dayjs from 'dayjs'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { acceptAnswer, denyAnswer } from '../../api'
import { QuestionTypeAPI, StoreAnswersTypeAPI } from '../../api/types'
import { Button } from '../../elements/Button'
import { ImagesWithPreview } from '../../elements/ImagesWithPreview'
import { ListCard } from '../ListCard'

import styles from './AnswerCard.module.scss'


type AnswerCardProps = {
  storeAnswers: StoreAnswersTypeAPI
  questions: QuestionTypeAPI[]
  grey: boolean
}


export const AnswerCard = ({
  storeAnswers,
  questions,
  grey
}: AnswerCardProps) => {
  const i18n = useTranslation()

  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [showButtons, setShowButtons] = useState(!storeAnswers.confirmedAt)


  const getAnswer = useCallback((question: QuestionTypeAPI) => {
    return storeAnswers.answers.find(a => a.questionId === question.id)
  }, [storeAnswers.answers])


  const handleAccept = useCallback(() => {
    setLoading(true)
    acceptAnswer(storeAnswers.missionStoreId).then(response => {
      if (!response.success) {
        toast.error(i18n.t('ToastError:ErrorAcceptingAnswers'))
      } else {
        toast.success(i18n.t('ToastError:AnswerAccepted'))
        setShowButtons(false)
      }
      setLoading(false)
    })
  }, [i18n, storeAnswers.missionStoreId])


  const handleDeny = useCallback(() => {
    setLoading(true)
    denyAnswer(storeAnswers.missionStoreId).then(response => {
      if (!response.success) {
        toast.error(i18n.t('ToastError:ErrorDenyingAnswers'))
      } else {
        toast.success(i18n.t('ToastError:AnswerDenied'))
        setShowButtons(false)
      }
      setLoading(false)
    })
  }, [i18n, storeAnswers.missionStoreId])


  return (
    <ListCard
      grey={grey}
      handler={() => setIsOpen(!isOpen)}
    >
      <div className={styles.answerCard}>
        <div className={styles.header}>
          <h3>
            {storeAnswers.storeName}
          </h3>
          <div className={styles.right}>
            {storeAnswers.outsideRange && (
              <h5 className={styles.pill}>
                {i18n.t('Answers:OutsideRange')}
              </h5>
            )}
            <h4>
              {dayjs(storeAnswers.answeredAt).format('DD MMM YYYY')}
            </h4>
          </div>
        </div>
        {isOpen && (
          <>
            <span>
              {`${i18n.t('Answers:CreatedAt')}: ${dayjs(storeAnswers.createdAt).toString()}`}
            </span>
            <span>
              {`${i18n.t('Answers:AnsweredAt')}: ${dayjs(storeAnswers.answeredAt).toString()}`}
            </span>
            <span className={`${styles.lastDate} ${!storeAnswers.confirmedAt && styles.italic}`}>
              {`${i18n.t('Answers:ValidatedAt')}: ${storeAnswers.confirmedAt ? dayjs(storeAnswers.confirmedAt).toString() : i18n.t('Mission:ToBeValidated')}`}
            </span>
          </>
        )}
        {questions.map(q => (
          <div className={styles.question} key={`question_${q.id}_AnswerCard`}>
            <h3 className={styles.id}>
              {`${q.order+1})`}
            </h3>
            <div className={styles.questionInfo}>
              {isOpen ? (
                <h4>
                  {q.text}
                </h4>
              ) : (
                <div className={styles.top}>
                  <div className={styles.smallImgContainer}>
                    <ImagesWithPreview
                      images={getAnswer(q)?.photos || []}
                      withLabel
                    />
                  </div>
                  <h5 className={styles.response}>
                    {getAnswer(q)?.response}
                  </h5>
                </div>
              )}
              {isOpen && (
                <>
                  <h5 className={styles.response}>
                    {getAnswer(q)?.response}
                  </h5>
                  <div className={styles.photos}>
                    {getAnswer(q)?.photos.map(photo => (
                      <div
                        key={`photo_${photo}`}
                        className={styles.bigImgContainer}
                        style={{ width: `${100 / (getAnswer(q)?.photos.length || 1)}%` }}
                      >
                        <ImagesWithPreview
                          images={[photo]}
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
        {showButtons && (
          <div className={styles.validationButtons}>
            <Button
              title={i18n.t('Common:Deny')}
              handler={handleDeny}
              loading={loading}
              variant='danger'
            />
            <Button
              title={i18n.t('Common:Accept')}
              handler={handleAccept}
              loading={loading}
            />
          </div>
        )}
      </div>
    </ListCard>
  )
}
