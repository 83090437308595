import i18n from './localization'


const PORT = process.env.REACT_APP_PORT
const APP_ENV = process.env.REACT_APP_ENV

const WEBSITE_URL = process.env.REACT_APP_API_BASE_URL
const BASE_URL_DEV = `http://localhost:${PORT || 3001}`
const BASE_URL = APP_ENV === 'dev' ? BASE_URL_DEV : process.env.REACT_APP_API_BASE_URL
// README: This is the old website URL, used to redirect users to the old website
// IMPORTANT: we're assuming that the old website is running on port 3000
// When all app is in react, this will be unecessary
const BASE_URL_OLD_APP = APP_ENV === 'dev' ? 'http://localhost:3000' : WEBSITE_URL


export default {
  BASE_URL,
  BASE_URL_OLD_APP,
  WEBSITE_URL,
}

// ##################################
// ##
// ##     WIZARD CONSTANTS
// ##

const MAX_CHARS_MISSION_DESCRIPTION = 500
const NUMBER_OF_STORES_PER_PAGE = 50

const BINARY_ANSWERS = {
  no: { id: 'no', label: i18n.t('Common:No') },
  yes: { id: 'yes', label: i18n.t('Common:Yes') }
} as const
export type BinaryAnswerType = typeof BINARY_ANSWERS[keyof typeof BINARY_ANSWERS]

const QUESTION_TYPES = {
  binary: {
    id: 'binary',
    label: i18n.t('MissionWizard:QuestionTypeBinary'),
    hasOptions: false,
    expectedAnswerEnabled: true,
    defaultExpectedAnswer: BINARY_ANSWERS.no.id,
    validationEnabled: true
  },
  numeric: {
    id: 'numeric',
    label: i18n.t('MissionWizard:QuestionTypeNumeric'),
    hasOptions: false,
    expectedAnswerEnabled: true,
    defaultExpectedAnswer: '0',
    validationEnabled: false
  },
  free_text: {
    id: 'free_text',
    label: i18n.t('MissionWizard:QuestionTypeFreeText'),
    hasOptions: false,
    expectedAnswerEnabled: false,
    defaultExpectedAnswer: null,
    validationEnabled: false
  },
  multiple_choice: {
    id: 'multiple_choice',
    label: i18n.t('MissionWizard:QuestionTypeMultipleChoice'),
    hasOptions: true,
    expectedAnswerEnabled: false,
    defaultExpectedAnswer: null,
    validationEnabled: false
  },
  multiple_answer: {
    id: 'multiple_answer',
    label: i18n.t('MissionWizard:QuestionTypeMultipleAnswer'),
    hasOptions: true,
    expectedAnswerEnabled: false,
    defaultExpectedAnswer: null,
    validationEnabled: false
  }
} as const
export type QuestionsTypeType = typeof QUESTION_TYPES[keyof typeof QUESTION_TYPES]

const DEFAULT_ANSWER_OPTIONS = [{order: 0, text: ''}, {order: 1, text: ''}]

const DEFAULT_NEW_QUESTION = () => ({
  order: 0,
  text: '',
  shortName: '',
  questionType: QUESTION_TYPES.binary.id,
  expectedAnswer: null,
  image: null,
  requiresPhotoValidation: false,
  answerOptions: JSON.parse(JSON.stringify(DEFAULT_ANSWER_OPTIONS)),
  toValidate: false
})


export const WizardConsts = {
  BINARY_ANSWERS,
  DEFAULT_NEW_QUESTION,
  MAX_CHARS_MISSION_DESCRIPTION,
  NUMBER_OF_STORES_PER_PAGE,
  QUESTION_TYPES
}
