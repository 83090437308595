import dayjs from 'dayjs'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { MissionTypeAPI } from '../../api/types'
import { Map } from '../../components/Map'
import { QuestionAndValidations } from '../../components/QuestionAndValidations'
import { humanTimeDiference } from '../../utils/dates'

import styles from './MissionDetails.module.scss'


type MissionDetailsProps = {
  mission: MissionTypeAPI,
  storesToGet: 'stores' | 'temporaryPlaceOrStores'
}


export const MissionDetails = ({
  mission,
  storesToGet
}: MissionDetailsProps) => {
  const i18n = useTranslation()


  // README: In the future, when a question can have many validations per question, need to change this function
  const totalValueValidations = useMemo(() => {
    return mission.questions?.filter(q => q.toValidate)
                             .reduce((n, q) => n + ((q.validationValue || 0) * (q.maxValidations || 0)), 0).toFixed(2)
  }, [mission.questions])


  const totalValue = useMemo(() => {
    return (((Number(totalValueValidations)) + (mission.value || 0)) * (mission.temporaryPlaceOrStores?.length || 0)).toFixed(2)
  }, [mission.temporaryPlaceOrStores, totalValueValidations, mission.value])


  return (
    <>
      {/* General + Finances */}
      <div className={`${styles.partial} ${styles.wrap}`}>
        <div className={styles.halfPartial}>
          <div className={styles.id}>
            <span className={styles.idText}>
              {i18n.t('Common:General')}
            </span>
          </div>
          <div className={styles.info}>
            <div className={styles.infoPartial}>
              <h3>
                {`${i18n.t('Common:Name')}:`}
              </h3>
              <h5>
                {mission.title}
              </h5>
            </div>
            <div className={styles.infoPartial}>
              <h3>
                {`${i18n.t('Common:Description')}:`}
              </h3>
              <h5>
                {mission.description}
              </h5>
            </div>
            <div className={styles.infoPartial}>
              <h3>
                {`${i18n.t('MissionWizard:NumberOfQuestions')}:`}
              </h3>
              <h5>
                {mission.questions?.length}
              </h5>
            </div>
            <div className={styles.infoPartial}>
              <h3>
                {`${i18n.t('MissionWizard:NumberOfValidations')}:`}
              </h3>
              <h5>
                {mission.questions?.filter(q => q.toValidate).length}
              </h5>
            </div>
            <div className={styles.infoPartial}>
              <h3>
                {`${i18n.t('MissionWizard:NumberOfStores')}:`}
              </h3>
              <h5>
                {mission[storesToGet]?.length}
              </h5>
            </div>
          </div>
        </div>
        <div className={styles.halfPartial}>
          <div className={`${styles.info} ${styles.reversed}`}>
            <div className={styles.infoPartial}>
              <h3>
                {`${i18n.t('MissionWizard:ValuePerStore')}:`}
              </h3>
              <h5>
                {`${mission.value?.toFixed(2)}€`}
              </h5>
            </div>
            <div className={styles.infoPartial}>
              <h3>
                {`${i18n.t('MissionWizard:TotalValueStores')}:`}
              </h3>
              <h5>
                {`${((mission.value || 0) * (mission.temporaryPlaceOrStores?.length || 0)).toFixed(2)}€`}
              </h5>
            </div>
            <div className={styles.infoPartial}>
              <h3>
                {`${i18n.t('MissionWizard:ValueValidationsPerStore')}:`}
              </h3>
              <h5>
                {`${totalValueValidations}€`}
              </h5>
            </div>
            <div className={styles.infoPartial}>
              <h3>
                {`${i18n.t('MissionWizard:TotalValueValidations')}:`}
              </h3>
              <h5>
                {`${((Number(totalValueValidations)) * (mission.temporaryPlaceOrStores?.length || 0)).toFixed(2)}€`}
              </h5>
            </div>
            <div className={styles.infoPartial}>
              <h3>
                {`${i18n.t('MissionWizard:TotalMissionValue')}:`}
              </h3>
              <h5>
                {`${totalValue}€`}
              </h5>
            </div>
          </div>
          <div className={styles.id}>
            <span className={`${styles.idText} ${styles.reversed}`}>
              {i18n.t('Common:Finances')}
            </span>
          </div>
        </div>
      </div>
      {/* Questions + Validations */}
      <div className={styles.partial}>
        <div className={styles.id}>
          <span className={styles.idText}>
            {i18n.t('Common:Questions')}
          </span>
        </div>
        <div className={styles.infoQuestions}>
          {mission.questions?.map(question => (
            <QuestionAndValidations
              key={`questionAndValidationsInfo_${question.order}`}
              question={question}
              // README: In the future, when a question can have many validations, pass the array of validations to this prop
              validations={[{
                text: question.validationText || '',
                value: question.validationValue || 0,
                maxValidations: question.maxValidations || 0
              }]}
            />
          ))}
        </div>
      </div>
      {/* Stores */}
      <div className={`${styles.partial} ${styles.wrap}`}>
        <div className={styles.halfPartial}>
          <div className={styles.id}>
            <span className={styles.idText}>
              {i18n.t('Common:Stores')}
            </span>
          </div>
          <div className={styles.stores}>
            {mission[storesToGet]?.map((s, idx) => (
              <div className={styles.store} key={`storeInfo_${s.id}`}>
                <span className={styles.idStore}>
                  {idx+1}
                </span>
                <h4>
                  {s.name}
                </h4>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.mapContainer}>
          <Map
            markers={mission[storesToGet]?.map(s => ({
              position: [s.location.latitude, s.location.longitude],
              popUp: s.name,
              status: 'preview'
            })) || []
            }
          />
        </div>
      </div>
      {/* Dates */}
      <div className={styles.datesHeader}>
        <div className={styles.date}>
          <h4>
            {i18n.t('Mission:PreviewDate')}
          </h4>
        </div>
        <div className={styles.date}>
          <h4>
            {i18n.t('Mission:StartDate')}
          </h4>
        </div>
        <div className={styles.date}>
          <h4>
            {i18n.t('Mission:EndDate')}
          </h4>
        </div>
        <div className={styles.date}>
          <h4 className={mission.validationEndDate ? undefined : styles.italic}>
            {i18n.t('Mission:ValidationEndDate')}
          </h4>
        </div>
      </div>
      <div className={styles.datesDots}>
        <div className={styles.dot} />
        <div className={styles.dot} />
        <div className={styles.dot} />
        <div className={`${styles.dot} ${!mission.validationEndDate && styles.neutral}`} />
        <div className={styles.lines}>
          <div className={styles.lineEdge} />
          <div className={styles.line}>
            <p>
              {`${humanTimeDiference(mission.previewDate, mission.startDate)}`}
            </p>
          </div>
          <div className={styles.line}>
            <p>
              {`${humanTimeDiference(mission.startDate, mission.endDate)}`}
            </p>
          </div>
          <div className={`${styles.line} ${!mission.validationEndDate && styles.neutral}`}>
            <p>
              {mission.validationEndDate ? `${humanTimeDiference(mission.endDate, mission.validationEndDate)}` : '-'}
            </p>
          </div>
          <div className={styles.lineEdge} />
        </div>
      </div>
      <div className={styles.datesFooter}>
        <div className={styles.date}>
          <h5>
            {mission.previewDate && dayjs(mission.previewDate).toString()}
          </h5>
        </div>
        <div className={styles.date}>
          <h5>
            {mission.startDate && dayjs(mission.startDate).toString()}
          </h5>
        </div>
        <div className={styles.date}>
          <h5>
            {mission.endDate && dayjs(mission.endDate).toString()}
          </h5>
        </div>
        <div className={styles.date}>
          <h5 className={mission.validationEndDate ? undefined : styles.italic}>
            {mission.validationEndDate ? dayjs(mission.validationEndDate).toString() : i18n.t('Mission:NoValidations')}
          </h5>
        </div>
      </div>
    </>
  )
}
